<template>
  <div class="specialActivity">
    <Header>活动专区</Header>
    <div class="list">
      <div
        class="listItem"
        v-for="item in list"
        :key="item.id"
        @click="goDetail(item)"
      >
        <img :src="item.pic" alt="" />
        <div class="bottom">{{ item.title }}</div>
      </div>
      <div v-if="!list.length" class="def">
        <img src="../../../static/image/emptyBox.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'specialActivity',
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getActivityList();
  },
  methods: {
    // 点击进入详情
    goDetail(item) {
      if (item.link) {
        if (localStorage.getItem('token')) {
          window.location.href = `${item.link}?token=${localStorage.getItem(
            'token',
          )}&title=${item.title}`;
        } else {
          window.location.href = item.link + `?title=${item.title}`;
        }
      } else {
        this.$toast('活动尚未开始，敬请期待~');
      }
    },
    // 获取活动列表
    getActivityList() {
      this.$api.anyActivityList().then((e) => {
        if (e.code === 0) {
          this.list = e.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.specialActivity {
  .list {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
    .listItem {
      width: 100%;
      height: 170px;
      background: #2d6131;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 55px;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: flex-end;
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
  .def {
    text-align: center;
    margin: 50px auto 0;
    width: 100%;
    img {
      width: 35%;
      margin-right: -20px;
    }
    p {
      font-size: 14px;
      color: #aaa;
    }
  }
}
</style>
